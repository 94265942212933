import React, { Component } from 'react';
import DropIn from "braintree-web-drop-in-react";
import axios from 'axios';    
import swal from 'sweetalert';
import DatePicker from 'react-date-picker';
import {getApiHeaderGuest ,getGuestRechargeAttemptDetails} from '../../../utils/CommonFunctions';
import SimpleReactValidator from 'simple-react-validator';
import ErrorMsg from './../../public/errorMsg';
import PageLoader from './../../Loader/loader';
import Loader from "react-loader-spinner";

const SERVER_URL    = process.env.REACT_APP_SERVER_URL;

export class PaymentMethod extends Component {

    constructor(props) {

    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
        CardName: '',
        cardNumber:'',
        cardExpiry:'',
        cardCvv:'',
        termsCheckerr:false,
        plan_id: 1,
        cardExp:'',
        termsCheck:false,
        autoRecharge:true,
        autoRechargeCheck:"checked",
        discription:null,
        totalAmount:0,
        loading:true,
        clientToken:null,
        takefull_amount:null,
        payable_amount:null,
        rechargeType:null,
        activation_id:null,
        mobile_number:null,
        customer_id:null,
        plan_id:null,
        surchargeStatus:null,
        surchargeStringLabel:null,
        surchargeStringValue:null,
        surchargeAmount:null
        }
    }


    CardName = (event) => { this.setState({ CardName: event.target.value});}
    cardNumber = (event) => { this.setState({ cardNumber: event.target.value});}
    cardExpiry = (expiry) => 
    { 
        let year  = expiry.getFullYear();
        let month = expiry.getMonth() + 1;
        let cardDate  = month+'/'+year;

        this.setState({ cardExpiry: expiry, cardExp: cardDate});
        
    }
    cardCvv = (event) => { this.setState({ cardCvv: event.target.value});}
    autoRecharge = (renew) => { 

        
        this.setState({ autoRecharge: renew},()=>{


            let check = this.state.autoRecharge === true ? "checked" : null;

            this.setState({autoRechargeCheck: check});

        });
    

    }
    termsCheck = (check) => { this.setState({ termsCheck: check, termsCheckerr:false});}

 



    componentDidMount() { 
        this.setState({loading:true});

        this.getPaymentClientToken();

        this.getGuestRechargeAttemptDetails();  

        setTimeout(
              () => this.setState({loading:false}), 
              10000
        );

    }

    getGuestRechargeAttemptDetails = async () => {
        this.setState({loading:true});
        let attempt_data     = await getGuestRechargeAttemptDetails(); 
        //console.log('get recharge Attempt Data ==>', attempt_data.recharge_guest_attempt);

        if(attempt_data.recharge_guest_attempt != null && attempt_data.recharge_guest_attempt !== undefined)
        {
                if(attempt_data.recharge_guest_attempt.session_id != null && attempt_data.recharge_guest_attempt.session_id !== undefined)
                {
                    await sessionStorage.setItem('guest_recharge_session_id', attempt_data.recharge_guest_attempt.session_id);
                    this.setState({session_id:attempt_data.recharge_guest_attempt.session_id});
                    // alert(attempt_data.recharge_guest_attempt.session_id);
                        //alert(attempt_data.recharge_guest_attempt.recharge_type);
                        if(attempt_data.recharge_guest_attempt.recharge_type == 'MA-Recharge-Web'){
                            
                            this.setState({rechargeType:attempt_data.recharge_guest_attempt.recharge_type});

                            if(attempt_data.plan.plan_id != null && attempt_data.plan.plan_id !== undefined){

                                    this.setState({plan_id:attempt_data.plan.plan_id});

                                    let response    = attempt_data;

                                    this.setState({discription: response.plan.discription, totalAmount: response.total_amount, loading:true, takefull_amount:response.takefull_amount, payable_amount:response.payable_amount, amount:response.plan.amount});
                                    this.setState({surchargeStatus: response.surcharge_status, surchargeStringLabel: response.surchargeStringLabel, surchargeStringValue:response.surchargeStringValue, surchargeAmount:response.surchargeAmount});

                             }else{
                                   window.location.reload();
                             }
                            
                        }else{
                             //window.location.reload();
                             window.location.reload();
                        }

                        if(attempt_data.recharge_guest_attempt.activation_id != null && attempt_data.recharge_guest_attempt.activation_id !== undefined && attempt_data.recharge_guest_attempt.mobile_number != null && attempt_data.recharge_guest_attempt.mobile_number !== undefined && attempt_data.recharge_guest_attempt.customer_id != null && attempt_data.recharge_guest_attempt.customer_id !== undefined){

                             this.setState({activation_id: attempt_data.recharge_guest_attempt.activation_id});
                             this.setState({mobile_number: attempt_data.recharge_guest_attempt.mobile_number});
                             this.setState({customer_id: attempt_data.recharge_guest_attempt.customer_id});

                        }else{
                            window.location.reload();
                        }


                }else{ window.location.reload(); }

        }else{
            window.location.reload();
        }


        
    };







    mySubmitHandler = async (event) => { 

        event.preventDefault();  

        if (this.validator.allValid() == false) 
        {

            this.setState({loading:false});

            this.validator.showMessages();
            this.forceUpdate();
            return;
        } 

        if(this.state.termsCheck === true)
        {

            const { nonce } = await this.instance.requestPaymentMethod();

            this.setState({loading:true});

                const post_data =   {  
                                     
                                        payable_amount  : this.state.payable_amount,
                                        takefull_amount : this.state.takefull_amount,
                                        termsCheck      : this.state.termsCheck,
                                        auto_renew      : this.state.autoRecharge,
                                        session_id      : this.state.session_id,
                                        nonce           : nonce,
                                        plan_id         : this.state.plan_id,
                                        recharge_type   : this.state.rechargeType,
                                        activation_id   : this.state.activation_id,
                                        customer_id     : this.state.customer_id,
                                        mobile_number   : this.state.mobile_number

                                    };



                console.log(post_data);

                //let headers     = await getApiHeaderGuest();

                // let res         = await axios.post(`${ SERVER_URL }guest-recharge-submit`,post_data, { headers }).then(response => {
                let res         = await axios.post(`${ SERVER_URL }guest-recharge-submit`,post_data, {  }).then(response => {

                    this.setState({loading:false});
                    
                    if(typeof(response.data.message) !== 'undefined')
                    {
                        this.setErrorMessages(response.data.message,'success');

                        window.setTimeout(function(){
                                    window.location.reload();
                                 }, 5000);
                    }
                    else
                    {
                        this.setErrorMessages("Recharge Processed",'success');

                        window.setTimeout(function(){
                                    window.location.reload();
                                 }, 3000);
                    }

                    //this.props.nextStep();

                }).catch(error => {

                    this.setState({loading:false});

                    if((typeof(error.response.data.message) !== 'undefined') && (typeof(error.response.data) !== 'undefined'))
                    {
                        this.setErrorMessages(error.response.data.message,'warning');

                        if(error.response.data.data.telstra_api_called != null && error.response.data.data.telstra_api_called !== undefined)
                        {
                            if(error.response.data.data.telstra_api_called == 1){
                                 window.setTimeout(function(){
                                    window.location.reload();
                                 }, 3000);
                            }

                        }


                    }
                    else
                    {
                        swal("Internal Server Error", "", 'warning');
                    }
                    
                });

        }
        else
        {
         this.setState({ termsCheckerr: true});
        }
    };

    getPaymentClientToken= async ()=>{

        try 
        {

            let headers 	= await getApiHeaderGuest();
            
            let res 		= await axios.get(`${ SERVER_URL }payments/get-client-token`, { headers });

            let response    = res.data.data;

            this.setState({clientToken:response.clientToken});

            this.setState({loading:false});

        }
        catch(err)
        {

            this.setState({loading:false});

            if(err)
            {
                let errorMessage = '';

                if(err.response && err.response.data && err.response.data.message)
                {
                    if(typeof(err.response.data.message) === 'object')
                    {

                        swal({
                            title: "Error",
                            content: <ErrorMsg message={err.response.data.message}/>,
                            icon: "error"});
        
                    }
                    else
                    {
                        errorMessage = err.response.data.message;
                        swal(errorMessage,"error");
                        
                    }
                }
                else
                {
                    errorMessage = err;
                    swal(errorMessage,err);
                }
    


            }
            

        }

    }

    setErrorMessages = (errorMessage,type)=>{

        //let responseError = []

       let responseError = 'Internal server error';

        if (typeof(errorMessage) !== 'undefined' && errorMessage != null)
        {
            if(typeof errorMessage === 'object')
            {

                responseError = '';

                Object.keys(errorMessage).map(function(key, index) {

                    // responseError.push(errorMessage[key]);
                    responseError+=errorMessage[key];

                });

            }
            else
            {
                responseError = errorMessage;
            }

        }

        swal(responseError, "", type);

    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    render() {
        const { values } = this.props;

        return (
            <>
            {(this.state.loading === true) ? <PageLoader/> : null}
             <form className="kt-form kt-section__content kt-section__content--border common-layout" id="kt_form" onSubmit={this.mySubmitHandler}>
                                    <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" id="paymentBox">

                                            <p style={{color: "#000",fontSize:"16px", marginBottom:0}}>Pay & Proceed</p>

                                            <div className="kt-form__section kt-form__section--first" id="card_section">
                                                
                                                {/* <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Card Holder Name:</label>
                                                            <input type="text" className="form-control" name="card_name" placeholder="Enter Card Holder Name"  pattern='[A-Za-z\-\s]*' onChange={this.CardName} />
                                                            {this.validator.message('Card Holder Name', this.state.CardName, 'required', { className: 'text-danger' })}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Card Number:</label>
                                                            <input type="text" className="form-control" name="card_number" placeholder="Enter Card Number"  onChange={this.cardNumber} />
                                                            {this.validator.message('Card Number', this.state.cardNumber, 'required', { className: 'text-danger' })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>Expiry:</label>
                                                            <div>
                                                            <DatePicker
                                                                onChange={this.cardExpiry}
                                                                value={this.state.cardExpiry}
                                                                className="form-control customdate1"
                                                                format="MM-y"
                                                            
                                                            />
                                                            </div>
                                                            {this.validator.message('Expiry', this.state.cardExpiry, 'required', { className: 'text-danger' })}
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label>CVV:</label>
                                                            <input type="text" className="form-control " id="card_cvv" name="card_cvv" placeholder="Enter CVV" onChange={this.cardCvv} />
                                                            {this.validator.message('CVV', this.state.cardCvv, 'required', { className: 'text-danger' })}
                                                        </div>
                                                    </div>
                                                </div> */}
                                                
                                                {(this.state.clientToken !== null) ? 
                                                <>
                                                <DropIn options={{ authorization: this.state.clientToken, card:{ cardholderName: {'required':true}, 
                                                          overrides: {
                                                            styles: {
                                                              input: {
                                                                width:'100%'
                                                              }
                                                            }
                                                          }
                                            
                                            }}}
                                                onInstance={(instance) => (this.instance = instance)}
                                                />
                                                </>
                                                
                                                : <div style={{width:"100%"}}><p style={{textAlign:"center", fontSize:"18px", color:"red", fontWeight:"bold"}}>Loading...</p></div>}
                                                <div className="row">
                                                
                                                    {/* <div className="col-lg-6">
                                                        <div className="form-group  row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <label className="kt-option" style={{padding: '10px'}}>
                                                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                                                        <input type="checkbox" name="agree_terms" id="agree_terms" value={this.state.autoRecharge} defaultChecked={this.state.autoRecharge}  onChange={e => {this.autoRecharge(e.target.checked);}}/>
                                                                        <span></span>
                                                                    </label>
                                                                    <span className="kt-option__label">
                                                                        <span className="kt-option__head">
                                                                            <span className="kt-option__title">
                                                                                Enable Auto Recharge
                                                                            </span>

                                                                        </span>
                                                                    </span>
                                                                </label>

                                                              
                                                                
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    
                                                </div>

                                                <div className="kt-invoice-1">
                                                    <div className="kt-invoice__body kt-padding-10" id="payment_cart">   
                                                        <div className="kt-invoice__container">
                                                            <div className="table-responsive">

                                                                
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>DESCRIPTION</th>
                                                                            <th>AMOUNT DUE</th>
                                                                        </tr>
                                                                    </thead>
                                                                        <tbody>  
                                                                           
                                                                            
                                                                                <tr><td>{this.state.discription}</td><td>${this.state.amount}</td></tr>

                                                                                
                                                                                {(this.state.surchargeStatus !== null) ? 
                                                                                

                                                                                        <tr>
                                                                                            <td>{this.state.surchargeStringLabel}
                                                                                                <span style={{display: 'block', fontSize: '13px', fontWeight: '300', fontStyle: 'italic', color: '#807f7f', textAlign: 'left'}}>{this.state.surchargeStringValue}</span>
                                                                                            </td>
                                                                                            <td>${this.state.surchargeAmount}</td>
                                                                                        </tr>


                                                                                        :
                                                                                        null
                                                                            
                                                                                }
                                                                                

                                                                         

                                                                        </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div className="kt-invoice__footer kt-padding-10">
                                                            <div className="kt-invoice__container">
                                                                <div className="kt-invoice__bank">
                                                                </div>
                                                                <div className="kt-invoice__total pull-right">
                                                                    <span className="kt-invoice__title">TOTAL AMOUNT</span>
                                                                    <span className="kt-invoice__price">${this.state.totalAmount}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>											                
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <label style={{display:'none'}}>Your card will be charged 1 AUD for card preauthorization. This pending transaction will be voided by your bank automatically within 7-14 business days.</label>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group  row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <label className="kt-option">
                                                                    <label className="kt-checkbox kt-checkbox--bold kt-checkbox--danger">
                                                                        <input type="checkbox" name="agree_terms" id="agree_terms" value="1"  onChange={e => {this.termsCheck(e.target.checked);}} />
                                                                        <span></span>
                                                                    </label>
                                                                    <span className="kt-option__label">
                                                                        <span className="kt-option__head">
                                                                            <span className="kt-option__title">
                                                                                I agree to Telsim's <a href="https://telsim.com.au/terms-and-conditions" target="_blank">terms & conditions</a> and<a href="https://telsim.com.au/website-privacy-policy" target="_blank"> policies.</a>
                                                                            </span>

                                                                        </span>
                                                                    </span>
                                                                </label>

                                                                {this.state.termsCheckerr == true &&
                                                                <span className="" role="alert"><span id="authorized_error" style={{color: 'red',fontWeight: '400',paddingTop:'10px',display:'block'}}><i className="fa fa-exclamation-triangle"></i> Please confirm checkbox</span></span>
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                    
                                            <br/>
                                            <div className="kt-form__actions">
                                            <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="backBtn" onClick={this.back}>Back</button>&nbsp;
                                            <button type="submit" className="btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper  btn-pill btn-sm" id="wizard_next_btn" data-ktwizard-type="action-next" disabled={this.state.loading}>Pay & Recharge</button>
                                            </div>

                </div>      
            </form> 
            </>  
        );
    }
}

export default PaymentMethod;
